<template>
  <div class="max">
    <div class="container">
      <div class="row justify-content-start">
        <div class="col-1"></div>
        <div class="col-10">
          <video width="1000" height="650" controls="controls">
            <source src="../assets/img/product-max-1.mp4" type="video/mp4" />Your browser does not support the video tag.
          </video>
        </div>
        <div class="col-1"></div>
      </div>
      <div class="row justify-content-start">
        <div class="col-1"></div>
        <div class="col-8">
          <p style="color:#000000; font-size:20px; font-weight: bold; margin:20px 0px 20px 0px;">产品介绍 -MAX系列</p>
          <img src="../assets/img/home-5.jpg" style="width:750px"/>
          <img src="../assets/img/top-1.jpg" style="width:750px"/>
          <img src="../assets/img/top-2.jpg" style="width:750px"/>
          <img src="../assets/img/top-3.jpg" style="width:750px"/>
          <img src="../assets/img/top-4.jpg" style="width:750px"/>
          <img src="../assets/img/top-5.jpg" style="width:750px"/>
          <img src="../assets/img/top-6.jpg" style="width:750px"/>
          <img src="../assets/img/top-7.jpg" style="width:750px"/>
          <img src="../assets/img/top-8.jpg" style="width:750px"/>
          <img src="../assets/img/top-9.jpg" style="width:750px"/>
          <img src="../assets/img/top-10.jpg" style="width:750px"/>
        </div>
        <div class="col-2">
          <v-side style="width:240px; height:600px;" />
        </div>
        <div class="col-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Side from '../components/Side'
export default {
  components: {
    'v-side': Side
  }
}
</script>
